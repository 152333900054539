import { FC, memo } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Icon, IconColor, IconSize, IconTypes } from '@app/ui'
import { ROUTES } from '@app/constants'
import i18n from '@app/libraries/i18n'
import { Ads } from '@app/graphql'
import { PrivateChannelState, PublicChannelState } from '@app/storage'

const MENU_ITEMS = [
  {
    title: i18n.t('common.home'),
    iconType: IconTypes.home,
    route: ROUTES.feed.short,
  },
  {
    title: i18n.t('common.channel'),
    iconType: IconTypes.spin,
    route: ROUTES.channel.short,
  },
  {
    title: i18n.t('common.voting'),
    iconType: IconTypes.events,
    route: ROUTES.matchesList.short,
  },
]

interface NavigationMenuProps {
  adsData?: Partial<Ads>
}

const NavigationMenu: FC<NavigationMenuProps> = ({ adsData }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const handleAdsClick = () => {
    if (adsData?.referral) {
      window.open(adsData.referral, '_blank').focus()
    }
  }

  return (
    <StyledNavigationContainer className="vb-flex-column-fs-c" $withAds={Boolean(adsData?.imageUrl)}>
      {
        adsData?.imageUrl ?
          <div className="banner-container" onClick={handleAdsClick}>
            <img src={adsData.imageUrl} alt={t('common.advertisement')}/>
          </div> :
          null
      }
      <StyledNavigationRow className="vb-flex-row-spb-c">
        {
          MENU_ITEMS.map(menuItem => (
            <StyledButton
              key={menuItem.title}
              className="vb-flex-column-c-c"
              to={menuItem.route}
              $isCurrent={pathname === menuItem.route}
              onClick={() => {
                PrivateChannelState.scrollPosition = 0
                PublicChannelState.scrollPosition = 0
              }}
            >
              <Icon
                type={menuItem.iconType}
                color={pathname === menuItem.route ? IconColor.brand : IconColor.grayNavigation}
                size={IconSize.small}
              />
              <span className="item-text">{t(menuItem.title)}</span>
            </StyledButton>
          ))
        }
      </StyledNavigationRow>
    </StyledNavigationContainer>
  )
}

const StyledNavigationContainer = styled.div<{ $withAds?: boolean }>`
  position: fixed;
  background-color: white;
  height: ${props => props.$withAds ? '118' : '56'}px;
  left: 0;
  right: 0;
  bottom: 0;
  filter: drop-shadow(0 -2px 4px rgba(0, 0, 0, 0.1));
  border-top: 1px solid #ccc;
  
  .banner-container {
    width: 100%;
    height: 62px;
    
    img {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`

const StyledNavigationRow = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 56px;
`

const StyledButton = styled(Link)<{ $isCurrent: boolean }>`
  text-decoration: none;
  height: 40px;
  width: 40px;
  background-color: white;

  .item-text {
    font-size: var(--font-size-tiny);
    line-height: var(--font-size-normal);
    font-weight: 500;
    text-align: center;
    color: ${props => props.$isCurrent ? 'var(--color-brand)' : 'var(--color-gray-navigation)'};
  }
`

export default memo(NavigationMenu)
