import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonSize, ButtonType } from '@app/types'

interface ButtonProps {
  id?: string;
  className?: string
  size?: ButtonSize
  to?: string
  href?: string
  fluid?: boolean
  type?: ButtonType
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement|HTMLAnchorElement, MouseEvent>) => void
}

const Button: React.FC<ButtonProps> = props => {

  const classes = [
    'Button',
    'size-'+(props.size ? props.size : 'normal'),
    'type-'+(props.type ? props.type : 'normal'),
    props.className,
    props.fluid ? 'fluid' : '',
    props.disabled ? 'disabled' : ''
  ].join(' ')

  return <ButtonStyled
    id={props.id}
    as={props.href ? 'a' : props.to ? Link : 'button'}
    to={props.disabled ? '' : props.to}
    href={props.href}
    onClick={props.onClick}
    className={classes}
    disabled={props.disabled}
  >
    {props.children}
  </ButtonStyled>
}

const ButtonStyled = styled.button`

  border: none;
  border-radius: 12px;
  color: var(--font-color-primary);
  background-color: #F4F5F8;
  text-decoration: none;
  font-size: var(--font-size-normal);

  &.size-small {
    // TODO
  }
  &.size-normal {
    padding: 0.5rem;
  }
  &.size-big {
    font-size: var(--font-size-big);
    padding: 1rem 0.5rem;
    font-weight: bold;
  }

  &.type-primary {
    background-color: var(--color-brand);
  }

  &.type-primary-dark {
    background-color: var(--color-brand-dark);
  }

  &.type-primary-pale {
    background-color: var(--color-brand-pale);
  }

  &.type-dark {
    background-color: #4D4D4D;
  }

  &.type-white {
    background-color: white;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 -1px 6px rgba(0, 0, 0, 0.1);
  }

  &.type-transparent {
    background-color: transparent;
  }

  &.type-warning {
    background-color: var(--color-warning);
    color: var(--font-color-inverted);
  }
  &.type-primary-warning {
    background-color: var( --color-brand);
    color: var(--color-warning);
  }

  &.fluid {
    width: 100%;
  }

  &.disabled {
    opacity: .3;
  }
`

export default Button
