// Root
const root = '/'
// Login
const login = 'login'
const personalInfo = 'personal-info'
const interests = 'interests'
const showtime = 'showtime'
// Tipster Onboarding
const tipsterOnboarding = 'tipster'
const tipsterPersonalInfo = 'personal-info'
const tipsterInterests = 'interests'
const tipsterShowtime = 'showtime'
const tipsterMarket = 'market'
const tipsterBookie = 'bookies'
const tipsterChannel = 'channel'
const tipsterProfile = 'service'
const tipsterService = 'service'
// Feed
const feed = 'feed'
const content = 'content'
const channel = 'channel'
// App Settings
const appSettings = 'app-settings'
// Contact Form
const contactForm = 'contact-form'
// Matches list
const matchesList = 'matches-list'
// Saved tips
const savedTips = 'saved-tips'
// Notifications
const notifications = 'notifications'
// Inbox
const inbox = 'inbox'
// Profile
const profile = 'profile'
// Events voted
const matchesVoted = 'matches-voted'
// Banned screen
const banned = 'banned'
// Invite
const invite = 'channel-invite'
// Payment
const payment = 'payment'
const paymentResult = 'payment-result'
// Legal
const legal = 'legal'
// User subscriptions
const userSubscriptions = 'user-subscriptions'
// Tipster subscriptions
const tipsterSubscriptions = 'tipster-subscriptions'
const tipsterSubscriberList = 'subscribers'
// Terms and contion
const tandC = 'https://votebetting.app/legal'

// Install app
const install = 'install'

export const ROUTES = {
  // Root
  root: `${root}`,
  // Login
  login: {
    short: `/${login}`,
    asParent: `/${login}/*`,
  },
  loginPersonalInfo: {
    short: `/${personalInfo}`,
    full: `/${login}/${personalInfo}`,
  },
  loginInterests: {
    short: `/${interests}/:onboardingStage`,
    full: `/${login}/${interests}/:onboardingStage`,
  },
  loginShowtime: {
    short: `/${showtime}`,
    full: `/${login}/${showtime}`,
  },
  // Tipster Onboarding
  tipsterOnboarding: {
    short: `/${tipsterOnboarding}/`,
    asParent: `/${tipsterOnboarding}/*`,
  },
  tipsterOnboardingPersonalInfo: {
    short: `/${tipsterPersonalInfo}`,
    full: `/${tipsterOnboarding}/${tipsterPersonalInfo}`,
  },
  tipsterOnboardingInterests: {
    short: `/${tipsterInterests}/:onboardingStage`,
    full: `/${tipsterOnboarding}/${tipsterInterests}/:onboardingStage`,
  },
  tipsterOnboardingShowtime: {
    short: `/${tipsterShowtime}`,
    full: `/${tipsterOnboarding}/${tipsterShowtime}`,
  },
  tipsterOnboardingBookie: {
    short: `/${tipsterBookie}`,
    full: `/${tipsterOnboarding}/${tipsterBookie}`,
  },
  tipsterOnboardingMarket: {
    short: `/${tipsterMarket}`,
    full: `/${tipsterOnboarding}/${tipsterMarket}`,
  },
  tipsterOnboardingProfile: {
    short: `/${tipsterProfile}/:step`,
    full: `/${tipsterOnboarding}/${tipsterProfile}/:step`,
  },
  tipsterOnboardingChannel: {
    short: `/${tipsterChannel}`,
    full: `/${tipsterOnboarding}/${tipsterChannel}`,
  },
  tipsterOnboardingService: {
    short: `/${tipsterService}`,
    full: `/${tipsterOnboarding}/${tipsterService}`,
  },
  // Channels
  feed: {
    short: `/${feed}`,
  },
  content: {
    full: `/${content}/:id`,
    short: `/${content}`,
  },
  channel: {
    short: `/${channel}`
  },
  channelProfile: {
    full: `/${channel}/:id`,
    short: `/${channel}`,
  },
  // App Settings
  appSettings: {
    short: `/${appSettings}`,
  },
  // Contact Form
  contactForm: {
    short: `/${contactForm}`,
  },
  // Matches list
  matchesList: {
    short: `/${matchesList}`
  },
  // Saved tips
  savedTips: {
    short: `${root}${savedTips}`
  },
  // Profile
  profile: {
    full: `${root}${profile}/:id`,
    short: `${root}${profile}`
  },
  // Notifications
  notifications: {
    short: `${root}${notifications}`
  },
  // Inbox
  inbox: {
    short: `${root}${inbox}`
  },
  // Matches voted
  matchesVoted: {
    short: `${root}${matchesVoted}`
  },
  // Banned screen
  banned: {
    short: `${root}${banned}`
  },
  // Invite
  invite: {
    short: `${root}${invite}`
  },
  // Payment
  payment: {
    short: `${root}${payment}`,
  },
  paymentResult: {
    short: `${root}${paymentResult}`,
  },
  // Legal
  legal: {
    short: `/${legal}`
  },
  // install
  install: {
    short: `${root}${install}`
  },
  // User subscriptions
  userSubscriptions: {
    short: `${root}${userSubscriptions}`,
  },
  tipsterSubscriptions: {
    short: `${root}${tipsterSubscriptions}`,
  },
  tipsterSubscriberList: {
    short: `${root}${tipsterSubscriberList}`,
  },
  termsAndConditon: {
    short: tandC,
  },
}

export const AFFILIATE_LINK_INTERNAL_NAME_KEY = 'affiliateLinkName'
export const INVITE_LINK_INTERNAL_NAME_KEY = 'inviteLinkName'
export const FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY = 'followLinkName'
export const INVITEE_TIPSTER_LINK_INTERNAL_NAME_KEY = 'tivb'
export const APPLICATION_DEV_DOMAIN = 'https://newdev.votebetting.app/'
export const APPLICATION_PROD_DOMAIN = 'https://www.votebetting.app/'
