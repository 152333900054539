import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import { IconTypes } from '@app/ui'
import env from '@app/environment'
import {
  AFFILIATE_LINK_INTERNAL_NAME_KEY,
  FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY,
  INVITE_LINK_INTERNAL_NAME_KEY,
  INVITEE_TIPSTER_LINK_INTERNAL_NAME_KEY,
  ROUTES,
  SEARCH_PARAM_VALUES,
  SEARCH_PARAMS
} from '@app/constants'
import { CurrentUserState } from '@app/storage'
import posthog from 'posthog-js'
import { UserOnboardingStages, UserRoles } from '@app/graphql'

export const authByFacebook = async (queryParams = '') => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
      customState: encodeURIComponent(queryParams),
    })
    localStorage.setItem('isAuth', 'true')
    CurrentUserState.auth_type = 'facebook'
    return { data: credentials }
  } catch (e) {
    return { error: e }
  }
}

export const authByGoogle = async (queryParams = '') => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: encodeURIComponent(queryParams),
    })
    localStorage.setItem('isAuth', 'true')
    CurrentUserState.auth_type = 'google'
    return { data: credentials }
  } catch (e) {
    return { error: e }
  }
}

export const getCurrentUser = async () => {
  try {
    const authType = localStorage.getItem('auth_type')
    if (authType === 'telegram') {
      return { data: JSON.parse(localStorage.getItem('telegram_user')) }
    } else {
      const userInfo = await Auth.currentAuthenticatedUser()
      return { data: userInfo }
    }
  } catch (e) {
    return { error: e }
  }
}

export const getUserAttributes = async (): Promise<{ name: string, family_name: string, email: string }> => {
  try {
    const data = await Auth.currentUserInfo()
    return data?.attributes || null
  } catch (e) {
    console.error('get user attributes Error: ', e)
    return null
  }
}

export const logOut = async () => {
  try {
    const authType = localStorage.getItem('auth_type')
    localStorage.removeItem('isAuth')
    localStorage.removeItem('auth_type')
    localStorage.removeItem('newChannelId')
    localStorage.removeItem('useProgreeBarLoader')
    localStorage.removeItem('currentTab')
    localStorage.removeItem('onboardingFlow')
    if (authType === 'telegram') {
      localStorage.removeItem('telegram_user')
    } else {
      await Auth.signOut()
      // eslint-disable-next-line max-len
      window.location.href = `https://${env.AWS_OAUTH_DOMAIN}.auth.${env.AWS_PROJECT_REGION}.amazoncognito.com/logout?client_id=${env.AWS_USER_POOLS_WEB_CLIENT_ID}&logout_uri=${env.AWS_OAUTH_REDIRECT_SIGN_OUT}`
    }
    posthog.reset()
    return { success: true }
  } catch (e) {
    return { error: e, success: false }
  }
}

export const validateEmail = (email: string) => {
  return email != null && email.toLowerCase().match(
    // eslint-disable-next-line max-len
    new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  )
}

export const validatePhoneNumber = (phone: string): boolean => {
  return new RegExp(/^\+?(\d*\s?\d+)*\s?$/).test(phone)
}

export const validateDisplayName = (name: string): boolean => {
  // restrict special characters
  return !new RegExp(/[-!$%^&*()_+|~=`\\#{}\[\]:";'<>?,.\/@±§]/g).test(name) // eslint-disable-line no-useless-escape
}

export const shareLinks = (link: string) => {
  const telegram = {
    name: 'Telegram',
    link: `https://telegram.me/share/url?url=${link}`,
    iconType: IconTypes.telegram,
  }
  const facebook = {
    name: 'Facebook',
    link: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
    iconType: IconTypes.facebook,
  }
  const whatsapp = {
    name: 'WhatsApp',
    link: `https://api.whatsapp.com/send?text=${link}`,
    iconType: IconTypes.whatsApp,
  }
  const twitter = {
    name: 'Twitter',
    link: `https://twitter.com/intent/tweet?url=${link}`,
    iconType: IconTypes.twitter,
  }
  const viber = {
    name: 'Viber',
    link: `viber://forward?text=${link}`,
    iconType: IconTypes.viber,
  }

  return { telegram, facebook, whatsapp, twitter, viber }
}

export const getSearchParams = () => {
  const params = new URLSearchParams(window.location.search)
  return {
    affiliateLinkName: params?.get(AFFILIATE_LINK_INTERNAL_NAME_KEY),
    inviteLinkName: params?.get(INVITE_LINK_INTERNAL_NAME_KEY),
    followLinkName: params?.get(FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY),
    inviteeTipsterLinkName: params?.get(INVITEE_TIPSTER_LINK_INTERNAL_NAME_KEY),
  }
}
// todo: temporary forbid to create new users
export const checkExistingProdUser = (): boolean => {
  const params = new URLSearchParams(window.location.search)
  const existingUserParam = params?.get(SEARCH_PARAMS.existingUser)
  return existingUserParam !== SEARCH_PARAM_VALUES[SEARCH_PARAMS.existingUser] && env.APP_ENVIRONMENT === 'prod'
}

export const prepareInviteLink = (linkName: string): string => {
  if (!linkName) return null
  const domain = window.location.origin
  return encodeURI(domain + '?' + INVITE_LINK_INTERNAL_NAME_KEY + '=' + linkName)
}
export const prepareFollowLink = (linkName: string): string => {
  if (!linkName) return null
  const domain = window.location.origin
  return encodeURI(domain + '?' + FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY + '=' + linkName)
}

export const copyInviteLink = (linkName: string): boolean => {
  if (linkName && 'clipboard' in navigator) {
    const encodedUrl = prepareInviteLink(linkName)
    navigator.clipboard.writeText(encodedUrl)
    return true
  }
  return false
}

export const onboardingRedirectUrl = (id, userType, currentOnboardingStage) => {
  const isTipsterWorkflow = (userType || localStorage.getItem('onboardingFlow')) === UserRoles.Tipster
  const tipsterInterestsOnboardingURL = (
    ROUTES.tipsterOnboardingInterests.full
      .replace(':onboardingStage', (currentOnboardingStage ?? '').toLowerCase().replaceAll('_', '-'))
  )
  const userInterestsOnboardingURL = (
    ROUTES.loginInterests.full
      .replace(':onboardingStage', (currentOnboardingStage ?? '').toLowerCase().replaceAll('_', '-'))
  )
  const onboardingRedirectPaths = {
    [ UserOnboardingStages.UpdatingPersonalInfo ]: (
      isTipsterWorkflow? ROUTES.tipsterOnboardingPersonalInfo.full :ROUTES.loginPersonalInfo.full
    ),
    [ UserOnboardingStages.ChoosingFavoriteSports ]: (
      isTipsterWorkflow? tipsterInterestsOnboardingURL : userInterestsOnboardingURL
    ),
    [ UserOnboardingStages.CreatingChannel ]: ROUTES.tipsterOnboardingChannel.full,
  }

  return (
    localStorage.getItem('isAuth')? onboardingRedirectPaths[
      id? currentOnboardingStage: UserOnboardingStages.UpdatingPersonalInfo
    ]: ROUTES.root
  )
}
